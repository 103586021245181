<script lang="ts">
	import {
		convertToUSD,
		getCurrencyIcon,
		getCurrencyTypeText,
		getCurrentPrice,
		getCurrentPriceLabel
	} from '$lib/service/OrderService';
	import { numberWithCommas } from '$lib/service/WalletManager';
	import { priceRateProvider } from '$lib/stores/price';
	import type { MarketOrderDetailsV2 } from '$lib/types';
	import FontSpan from '../typography/FontSpan.svelte';

	let className = '';
	export { className as class };
	export let item: MarketOrderDetailsV2;

	let currentPrice = '';
	let loadCurrentPrice = (item: MarketOrderDetailsV2) => {
		getCurrentPrice(item).then((_currentPrice) => {
			currentPrice = _currentPrice;
		});
	};
	$: loadCurrentPrice(item);
	$: currentPriceLabel = getCurrentPriceLabel(item, numberWithCommas(currentPrice));
	$: currencyIcon = getCurrencyIcon(item);
	$: inUSD = convertToUSD(item.metadata.network, currentPrice, $priceRateProvider);
	$: currencyTypeText = getCurrencyTypeText(item);
</script>

<div class="flex flex-col self-center {className}">
	<FontSpan varient="body-R-06" class="flex text-gray-40 mb-1">{currentPriceLabel}</FontSpan>
	<h2 class="flex items-center">
		<div class="flex {currencyIcon}22 w-5 h-5 mr-2 self-start mt-1" />
		<FontSpan varient="body-B-04" class="flex text-white mr-1">{numberWithCommas(currentPrice)}</FontSpan>
		<FontSpan varient="body-R-07" class="flex mb-1 text-gray-40 self-end"
			>{currencyTypeText}</FontSpan
		>
	</h2>
	<FontSpan varient="body-R-07" class="flex text-gray-70">${inUSD}</FontSpan>
</div>
